<template>
  <v-card flat tile class="charcoal">
    <v-sheet
      color="transparent"
      class="pa-2 mx-0 mt-2"
      :style="charcoalTileMenu"
    >
      <v-card-title class="paper--text text-h4 pa-2 pl-0">
        Waist <span class="caption pl-0 mt-8 silver--text">History</span>
        <v-spacer />
        <v-switch
          color="progressActive "
          class="mr-0"
          dark
          title="Fine Scale?"
          v-model="finescale"
          @click="setScale"
        >
          <template v-slot:label>
            <span class="silver--text">Fine Scale?</span>
          </template>
        </v-switch>
      </v-card-title>
      <v-card-actions :class="textSizeXSmall + ' paper--text px-0 py-0'">
        <span class="pinkAccent--text mr-1"> Min: {{ min }} </span>
        <span class="blueAccent--text mr-1"> Max: {{ max }} </span>
        <span class="orange--text"> Avg: {{ avg }} </span>
        <v-spacer />
      </v-card-actions>
      <v-select
        solo
        height="60"
        dense
        color="nicegreen"
        item-color="black"
        background-color="charcoal lighten-2"
        dark
        v-model="groupby"
        return-object
        prepend-inner-icon="mdi-calendar"
        :items="interval"
        label="Interval"
        item-text="text"
        item-value="value"
        @change="loadItems"
        :menu-props="{
          closeOnClick: true,
          maxHeight: 280,
          transition: 'fab-transition'
        }"
      />
      <MyLineChart
        class="ml-n2"
        v-if="loaded"
        :chartdata="chartdata"
        :options="options"
        :key="switchnum"
      />
    </v-sheet>
  </v-card>
</template>

<script>
import axios from 'axios'
import { appConfig, builder } from '@/store/helpers.js'
import util from '@/mixins/util.js'
const MyLineChart = () =>
  import(/* webpackPrefetch: true */ '@/components/charts/MyLineChart.vue')
export default {
  components: { MyLineChart },
  mixins: [util],
  data: () => ({
    chartdata: {},
    options: {},
    items: [],
    values: [],
    labels: [],
    loaded: false,
    min: 0,
    max: 50,
    avg: 0,
    offsset: 10,
    switchnum: 0,
    stepsize: 5,
    finescale: false,
    groupby: { text: 'By Month', value: 'monthname' },
    interval: [
      { text: 'By Month', value: 'monthname' },
      { text: 'By Week', value: 'week' }
    ]
  }),
  beforeMount() {},
  mounted() {
    this.loadItems()
  },
  watch: {
    metric: function() {
      this.loadItems()
    }
  },
  props: {
    metric: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...appConfig,
    ...builder
  },
  methods: {
    twoDigits(what) {
      return Math.floor(what * 100) / 100
    },
    setScale() {
      if (this.finescale) this.stepsize = 1
      else this.stepsize = 5
      this.loadItems()
      this.switchnum++
    },
    loadItems() {
      {
        return axios
          .get(
            this.baseURL + '/stats/measurement/waist:' + this.groupby.value,
            {}
          )
          .then(response => {
            if (response.status == 200) {
              this.items = response.data.data
              let td = this.twoDigits

              this.labels = this.items.map(function(obj) {
                return obj.label
              })
              if (this.metric) {
                this.values = this.items.map(function(obj) {
                  return td(parseFloat(obj.metric))
                })
              } else {
                this.values = this.items.map(function(obj) {
                  return td(parseFloat(obj.imperial))
                })
              }
              this.min = this.arrayMin(this.values)
              this.max = this.arrayMax(this.values)
              this.avg = this.arrayAvg(this.values)
              let unit = this.metric ? 'cm' : 'inch'
              this.chartdata = {
                labels: this.labels,
                datasets: [
                  {
                    label: 'Avg. Waist - ' + this.groupby.text,
                    backgroundColor: '#fafafa',
                    borderColor: '#53a653',
                    borderWidth: 1,
                    data: this.values,
                    radius: 4,
                    pointHoverRadius: 15,
                    pointHoverBorderWidth: 10,
                    pointHoverBorderColor: '#212b31',
                    fill: false,
                    pointBackgroundColor: '#53a653',
                    lineTension: 0.1,
                    showLines: true
                  },
                  {
                    label: 'Avg.',
                    data: Array.apply(null, new Array(this.values.length)).map(
                      Number.prototype.valueOf,
                      this.avg
                    ),
                    fill: false,
                    radius: 0,
                    borderColor: 'orange',
                    borderWidth: 1,
                    border: '1'
                  },
                  {
                    label: 'Max',
                    data: Array.apply(null, new Array(this.values.length)).map(
                      Number.prototype.valueOf,
                      this.max
                    ),
                    fill: false,
                    radius: 0,
                    borderColor: '#b0e8ff',
                    borderWidth: 1,
                    border: '1'
                  },
                  {
                    label: 'Min',
                    data: Array.apply(null, new Array(this.values.length)).map(
                      Number.prototype.valueOf,
                      this.min
                    ),
                    fill: false,
                    radius: 0,
                    borderColor: '#ff63c5',
                    borderWidth: 1,
                    border: '1'
                  }
                ]
              }
              this.options = {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                  labels: {
                    fontColor: '#9e9e9e',
                    boxWidth: 2,
                    usePointStyle: false
                  }
                },
                scales: {
                  xAxes: [
                    {
                      gridLines: {
                        zeroLineColor: '#9e9e9e',
                        color: '#444'
                      },
                      ticks: {
                        stepSize: this.stepsize,
                        fontColor: '#efefef',
                        tickColor: '#9e9e9e'
                      }
                    }
                  ],
                  yAxes: [
                    {
                      suggestedMin: Math.max(this.min - this.offsset, 0),
                      suggestedMax: this.max + this.offsset,
                      gridLines: {
                        zeroLineColor: '#9e9e9e',
                        color: '#444'
                      },
                      ticks: {
                        stepSize: this.stepsize,
                        fontColor: '#efefef',
                        tickColor: '#9e9e9e',
                        callback: function(label) {
                          return label + ' ' + unit
                        }
                      }
                    }
                  ]
                }
              }
              this.loaded = true
              this.switchnum++
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    }
  }
}
</script>
